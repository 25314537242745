@import 'styles/component';
@import 'styles/mui';

body {
  font-family: Nunito, Roboto, sans-serif !important;
  font-size: 1rem;
  background-color: ghostwhite;
}

code,
pre,
input[type='code'],
textarea[type='code'] {
  font-family: 'Inconsolata', monospace !important;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

iframe {
  display: none !important;
}

:root {
  --color-primary-light: #31cb9e;
  --color-primary-main: #31cb9e;
  --color-primary-dark: #31cb9e;
}
