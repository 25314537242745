.MuiFormControl-root {
  > label {
    font-size: 18px;
  }
  .MuiFormHelperText-root {
    margin-top: 0px;
    font-size: 14px;
  }
}

.MuiOutlinedInput-notchedOutline > legend {
  width: unset;
  font-size: 14px;
}

.MuiTableContainer-root {
  margin: 16px 0px;

  .MuiTableBody-root > .MuiTableRow-root:last-child > .MuiTableCell-root {
    border-bottom: none;
  }
  .MuiTableRow-root:hover {
    background-color: #00000006;
  }
  .MuiTableCell-head {
    background-color: var(--color-primary-main) !important;
    color: #fff;
    padding: 8px 16px;
    text-align: center;
  }
  .MuiTableCell-body {
    padding: 8px 16px;
  }
  .MuiTableCell-body > .MuiCheckbox-root {
    padding: 6px;
  }
}

.MuiTabs-root,
.MuiTab-root {
  min-height: 40px !important;
  text-transform: none !important;
}

.MuiButton-root {
  text-transform: none !important;
}

.MuiButton-contained {
  color: #fff !important;
}

.MuiCheckbox-root {
  padding: 6px !important;
}

.MuiDialog-root {
  .MuiDialogTitle-root {
    background-color: var(--color-primary-main);
    color: #fff;
    padding: 8px 16px;
    text-align: center;
    font-size: 16px;
  }
  .MuiDialogContent-root {
    padding: 16px 16px !important;
  }
  .MuiDialogActions-root {
    padding: 12px 16px;
    border-top: 1px solid #0003;
  }
}

.MuiTabs-root {
  .MuiTabs-flexContainer {
    border-bottom: 1px solid #0003;
  }

  .MuiTab-root {
    border: 1px solid transparent;
    border-radius: 4px 4px 0px 0px;

    &.Mui-selected {
      border-color: #0003;
      border-bottom-color: transparent;
    }
  }
}

.MuiAccordion-root {
  &.MuiPaper-root::before {
    content: none;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
}
